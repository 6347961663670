import React from 'react'
import { NavigationDots, SocialMedia } from '../components'

const date = new Date().getFullYear()

const AppWrap = (Component, idName, classNames) =>
    // Higer Order Component
    function HOC() {
        return (
            <div id={idName} className={`app__container ${classNames}`}>
                <SocialMedia />
                <div className='app__wrapper app__flex'>
                    <Component />

                    <div className='copyright'>
                        <p className='p-text'>@{date} AYOUB MAIMMADI</p>
                        <p className='p-text'>All rights reserved</p>
                    </div>
                </div>
                <NavigationDots active={idName} />
            </div>
        )
    }

export default AppWrap
